import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { authApi } from './authApi';
import { decodeToken } from '../utils/jwt_decode';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../utils/ApiUrlConstants';


const axiosInstance: AxiosInstance = axios.create({
    baseURL: "",
    timeout: 60000,
    withCredentials: true,
});



axiosInstance.interceptors.request.use(
    async (config) => {
      const tokenValue = config.headers.Authorization?.toString().substring(7);
      console.log('token after substring is : '+tokenValue);
      
        const userDetails = localStorage.getItem('userDetails');
        if (userDetails) {
            const token =localStorage.getItem(ACCESS_TOKEN);
            if (!config.headers) {
                config.headers =config.headers||{};
            }
            if( (tokenValue === undefined) || (tokenValue.trim() !== localStorage.getItem('refresh_token')?.trim()) ) {
              config.headers.Authorization = `Bearer ${token}`;
            }
            
        }
        return config ;
    },
    (error) => {
        return Promise.reject(error);
    }
);


// axiosInstance.interceptors.response.use(
//     (response: AxiosResponse) => {
//         return response;
//     },
//     async (error) => {
//         if (error.response) {
//             const { status, config } = error.response;

//             if (status === 401 && !config.prevRequest) {
//                 try {
//                     config.prevRequest = true;
//                     const AccessToken = await authApi.refresh();
//                     if (AccessToken) {
//                         // Update the token in localStorage
//                         const user = decodeToken(AccessToken)
//                         localStorage.setItem("userDetails", JSON.stringify(user));
//                         // Update Authorization header
//                         config.headers.Authorization = `Bearer ${AccessToken}`;
//                         // Retry the original request
//                         return axiosInstance(config);
//                     }

//                 } catch (_error) {
//                     return Promise.reject(_error);
//                 }
//             }
//             if (status === 401) {
//                 window.location.href = '/401';
//             } else if (status === 403) {
//                 window.location.href = '/403';
//             } else if (status === 500) {
//                 window.location.href = '/500';
//             }
//         }
//         return Promise.reject(error);
//     }
// );


axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
      const originalRequest = error.config as AxiosRequestConfig & { _retry?: boolean };
  
      console.log('status of response : '+error.response?.status);
      // Si la réponse est 401 et que ce n'était pas une tentative de rafraîchissement
      if ((error.response?.status === 401 || error.response?.status === 403 ) && !originalRequest._retry) {
        console.log('la réponse est 401 ou 403');
        originalRequest._retry = true;
        
        try {
          const newAccessToken = await authApi.refreshToken();
          console.log('newAccessToken : '+newAccessToken);
        if (!originalRequest.headers) {
            originalRequest.headers = {};
            console.log('originalRequest.headers = {};');
          }
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          console.log('the header now contain the authorization header');
          console.log('originalRequest.headers : '+originalRequest.headers);
          console.log('originalRequest : '+originalRequest);
          return axiosInstance(originalRequest);
        } catch (err) {
          console.error('Unable to refresh token:', err);
        }
      }
  
      return Promise.reject(error);
    }
  );


export default axiosInstance;
