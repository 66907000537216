import React, { lazy, Suspense, ReactElement } from "react";
import { Route, Routes } from "react-router-dom";

import ForceRedirect from "./ForceRedirect";
import ProtectedRoute from "./PrivateRoute";
import Splash from "../common/components/ui/Splash";
import DashboardLayout from "../layouts/DashboardLayout";
import ChantierLayout from "../layouts/ChantierLayout";

// Route connexion
const Login = lazy(() => import("../pages/auth/Signin"));
const Register = lazy(() => import("../pages/auth/Signup"));
const Contact = lazy(() => import("../pages/contact/ContactForm"));
const NewsLetter = lazy(() => import("../pages/newsletter/NewLetterForm"));
const PlanningChantier = lazy(
  () => import("../pages/planning/planning-chantier/planningChantier")
);

const EmailVerification = lazy(() => import("../pages/auth/EmailVerfication"));
const ForgetPassword = lazy(() => import("../pages/auth/ForgetPassword"));
const ResetPassword = lazy(() => import("../pages/auth/ResetPassword"));

// Route Home
const ChantierList = lazy(() => import("../pages/chantiers/ChantierList"));
const Dashboard = lazy(() => import("../pages/dashboard/Dashboard"));
const AddChantier = lazy(() => import("../pages/chantiers/ChantierAdd"));

// Route CRA
const CraForm = lazy(() => import("../pages/crachantier/CraForm"));
const AllCraInMonth = lazy(() => import("../pages/crachantier/AllCraInMonth"));

const ChantierDetail = lazy(
  () => import("../pages/chantiers/DetailsChantier/ChantierDetails")
);

const DashboardDocument = lazy(
  () => import("../pages/document/DashboardDocument")
);
// Personnels

const Personnels = lazy(() => import("../pages/personnel/PersonnelList"));

// Fournisseurs

const Fournisseur = lazy(() => import("../pages/fournisseur/FournisseurList"));

const Depense = lazy(() => import("../pages/depense/DepenseList"));

const Recette = lazy(() => import("../pages/recette/RecetteList"));
const RecetteDetail = lazy(() => import("../pages/recette/RecetteDetails"));

const Actualite = lazy(() => import("../pages/actualite/ActualitePage"));

// Route erreur
const Error401 = lazy(() => import("../pages/401"));
const Error500 = lazy(() => import("../pages/500"));
const Error403 = lazy(() => import("../pages/403"));
const NotFound = lazy(() => import("../pages/NotFound"));
const NoAccess = lazy(() => import("../pages/NoAccess"));

//formulaire génerique
const AbonnementPage = lazy(
  () => import("../common/components/generiqueForm/AbonnementPage")
);

const Router = (): ReactElement => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={<Splash />}>
            <Login />
          </Suspense>
        }
      />
      <Route
        path="/inscription"
        element={
          <Suspense fallback={<Splash />}>
            <Register />
          </Suspense>
        }
      />
      <Route
        path="/contact"
        element={
          <Suspense fallback={<Splash />}>
            <Contact />
          </Suspense>
        }
      />
      <Route
        path="/newsletter"
        element={
          <Suspense fallback={<Splash />}>
            <NewsLetter />
          </Suspense>
        }
      />
      <Route
        path="/email-verification"
        element={
          <Suspense fallback={<Splash />}>
            <EmailVerification />
          </Suspense>
        }
      />
      <Route
        path="/forget-password"
        element={
          <Suspense fallback={<Splash />}>
            <ForgetPassword />
          </Suspense>
        }
      />
      <Route
        path="/reset-password"
        element={
          <Suspense fallback={<Splash />}>
            <ResetPassword />
          </Suspense>
        }
      />

      <Route path="/dashboard" element={<DashboardLayout />}>
        <Route
          index
          element={
            <Suspense fallback={<Splash />}>
              <main className="content" style={{ flex: 1 }}>
                <Dashboard />
              </main>
            </Suspense>
          }
        />
      </Route>

      <Route path="/chantier/get/:id" element={<ChantierLayout />}>
        <Route
          index
          element={
            <Suspense fallback={<Splash />}>
              <main className="content" style={{ flex: 1 }}>
                <ChantierDetail />
              </main>
            </Suspense>
          }
        />
      </Route>

      <Route path="/abonnement" element={<DashboardLayout />}>
        <Route
          index
          element={
            <Suspense fallback={<Splash />}>
              <main className="content" style={{ flex: 1 }}>
                <AbonnementPage />
              </main>
            </Suspense>
          }
        />
      </Route>

      <Route path="/personnels" element={<DashboardLayout />}>
        <Route
          index
          element={
            <Suspense fallback={<Splash />}>
              <main className="content" style={{ flex: 1 }}>
                <Personnels />
              </main>
            </Suspense>
          }
        />
      </Route>

      <Route path="/fournisseurs" element={<DashboardLayout />}>
        <Route
          index
          element={
            <Suspense fallback={<Splash />}>
              <main className="content" style={{ flex: 1 }}>
                <Fournisseur />
              </main>
            </Suspense>
          }
        />
      </Route>

      <Route path="/cra-global" element={<DashboardLayout />}>
        <Route
          index
          element={
            <Suspense fallback={<Splash />}>
              <main className="content" style={{ flex: 1 }}>
                <AllCraInMonth />
              </main>
            </Suspense>
          }
        />
      </Route>

      <Route
        path="/add-chantier"
        element={
          <Suspense fallback={<Splash />}>
            <AddChantier />
          </Suspense>
        }
      />
      <Route
        path="/cra-form"
        element={
          <Suspense fallback={<Splash />}>
            <CraForm />
          </Suspense>
        }
      />

      <Route
        path="/dashboard-document"
        element={
          <Suspense fallback={<Splash />}>
            <DashboardDocument chantier={""} />
          </Suspense>
        }
      />
      <Route
        path="/depenses"
        element={
          <Suspense fallback={<Splash />}>
            <Depense
              chantier={{
                id: "123",
                libelle: "aaal",
                adresse: "ddddd",
                statut: "dddddd",
                budget: 1223,
                avancement: 1,
                dateDebut: "",
                photo: "",
                responsable: "",
                client: "",
                photoClient: "",
              }}
            />
          </Suspense>
        }
      />
      <Route
        path="/recettes"
        element={
          <Suspense fallback={<Splash />}>
            <Recette
              chantier={{
                id: "123",
                libelle: "aaal",
                adresse: "ddddd",
                statut: "dddddd",
                budget: 1223,
                avancement: 1,
                dateDebut: "",
                photo: "",
                responsable: "",
                client: "",
                photoClient: "",
              }}
            />
          </Suspense>
        }
      />
      <Route
        path="/recette-detail"
        element={
          <Suspense fallback={<Splash />}>
            <RecetteDetail
              recette={{
                id: "220ba1ab-9dfd-424f-a1da-95e358dd6611",
                chantierLibelle: "Construction Lyon",
                chantierId: "aaa",
                date: "",
                facture: "null",
                reference: "fefefefe",
                libelle: "ffffff",
                montant: "1923",
              }}
            />
          </Suspense>
        }
      />
      <Route
        path="/actualite"
        element={
          <Suspense fallback={<Splash />}>
            <Actualite />
          </Suspense>
        }
      />
      <Route
        path="/401"
        element={
          <Suspense fallback={<Splash />}>
            <Error401 />
          </Suspense>
        }
      />
      <Route
        path="/500"
        element={
          <Suspense fallback={<Splash />}>
            <Error500 />
          </Suspense>
        }
      />
      <Route
        path="/403"
        element={
          <Suspense fallback={<Splash />}>
            <Error403 />
          </Suspense>
        }
      />
      <Route
        path="/not-found"
        element={
          <Suspense fallback={<Splash />}>
            <NotFound />
          </Suspense>
        }
      />
      <Route
        path="/no-access"
        element={
          <Suspense fallback={<Splash />}>
            <NoAccess />
          </Suspense>
        }
      />

      {/* Add other routes here */}
    </Routes>
  );
};

export default Router;
