export const a11yProps = (index: number) => {
  return {
    id: `ay-tab-${index}`,
    'aria-controls': `ay-tabpanel-${index}`
  };
};

export const isMobileScreen = (
  screenWidth: number = window.innerWidth
): boolean => {
  return screenWidth <= 600;
};
