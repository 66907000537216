import { ThemeOptions } from '@mui/material';
import { deepmerge } from '@mui/utils';
import palette from './palette';
import sharedTheme from '../SharedTheme';

export function DarkTheme(): ThemeOptions {
  return deepmerge(sharedTheme, {
    palette: palette,
    header: {
      background: '#fff',
      borderBottom: `0.5px solid ${palette.divider}`,
      textColor: palette.secondary.main
    }
  } as ThemeOptions);
}