import { Theme, ThemeOptions, createTheme } from '@mui/material';
import { deepmerge } from '@mui/utils';
import sharedTheme from '../SharedTheme';
import { enUS } from '@mui/material/locale';
import palette from './palette';
import ComponentsOverrides from '../../shared/overrides';


export function LightTheme(): Theme {
  const lightThemeOptions = deepmerge(sharedTheme, {
    palette: palette,
    header: {
      background: '#fff',
      borderBottom: `0.5px solid ${palette.divider}`,
      textColor: palette.secondary.main
    }
  } as ThemeOptions);

  const theme = createTheme(lightThemeOptions, enUS);
  theme.components = ComponentsOverrides(theme);
  return theme;
}
