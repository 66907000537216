import { combineReducers } from '@reduxjs/toolkit';
import { TRootState } from '../model/state';
import authReducer from './auth/auth.slice';
import usersReducer from './users/users.slice';
import layoutReducer from './layout/layout.slice';
import userContextReducer from './user-context/user-context.slice';


export const rootReducer = combineReducers<TRootState>({
  auth: undefined,
  context: undefined,
  users: undefined,
  chantiers: undefined,
  layout: {
    openSidebar: false,
    hideHeader: false
  }
  //auth: authReducer,
  //context: userContextReducer,
  //users: usersReducer,
  //layout: layoutReducer,
});
