import { Theme } from '@mui/material/styles';
import { Components } from '@mui/material/styles/components';

export default function Stepper(theme: Theme): Components {
  return {
    MuiStepLabel: {
      styleOverrides: {
        label: {
          //color: 'yellow'
        },
        labelContainer: {
          color: theme.palette.text.primary
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          width: '1.2em',
          height: '1.2em',
          padding: '2px',
          '&.Mui-active': {
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: '50%'
          }
        },
        active: {},
        text: {
          fill: theme.palette.common.white
          //color: 'yellow'
        }
      }
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          '& .MuiStep-horizontal': {
            paddingLeft: '32px',
            paddingRight: '32px'
          }
        },
        line: {
          borderColor: theme.palette.divider
        }
      }
    }
  };
}
