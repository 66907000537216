import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Button, Drawer, Typography, Stack } from "@mui/material";
// hooks
import useResponsive from "../../hooks/useResponsive";
// components
import Logo from "../../common/Logo";
import Scrollbar from "../../common/Scrollbar";
import NavSection from "../../common/NavSection";
//
import navConfig from "./NavConfig";
import CustomButton from "../../common/components/ui/CustomButton";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 220;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500],
}));

interface DashboardSidebarProps {
  isOpenSidebar: boolean;
  onCloseSidebar: () => void;
}

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
}: DashboardSidebarProps) {
  const { pathname } = useLocation();
  const isDesktop = useResponsive("up", "lg", 1, 10);

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        display: "flex",
        flexDirection: "column",
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ flexGrow: 1, mt: { lg: 13.5, md: 4, sm: 2, xs: 2 } }}>
        <NavSection navConfig={navConfig} onCloseSidebar={onCloseSidebar} />
      </Box>

      <Box sx={{ px: 1, pb: 1, mt: "auto" }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 1, borderRadius: 2 }}>
          <Box sx={{ textAlign: "center" }}>
            <Typography gutterBottom variant="h6">
              Avez-vous des questions ?
            </Typography>
          </Box>

          <CustomButton
            href="/contact"
            variant="contained"
          >
            Contactez-nous
          </CustomButton>
        </Stack>
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
              borderRightStyle: "dashed",
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
