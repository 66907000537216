import { all } from 'redux-saga/effects';
import { usersSagaWatcher } from './users/users.saga';
import { authSagaWatcher } from './auth/auth.saga';
import { layoutSagaWatcher } from './layout/layout.saga';
import { userContextSageWatcher } from './user-context/user-context.saga';


export default function* rootSaga(): Generator {
  
  yield console.log('rootSaga is running!');


  yield all([
    layoutSagaWatcher(),
    userContextSageWatcher(),
    usersSagaWatcher(),
    authSagaWatcher(),
  ]);
}
