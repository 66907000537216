import React, { useContext } from "react";
import "./App.css";
import { Provider } from "react-redux";
import store from "./core/state/store";
import { ThemeProvider } from "./theme/ThemeProvider";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { SnackbarProvider } from "notistack";
import { Toaster } from "react-hot-toast";
import useAuth from "./hooks/useAuth";
import Router from "./routes/Router";
import Splash from "./common/components/ui/Splash";
import CssBaseline from "@mui/material/CssBaseline";
import { loadStripe } from "@stripe/stripe-js";
import ValidationContext from "./common/components/generiqueForm/ValidationContext";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(
  "pk_test_51PLRDuP9v2g4IH2nUwi3JQZz1NrihmZC2I5ROrnMjSU60dzRjvJV93ebz5aYTLHkYEil9R4GwKMZVfAgdclkbdfW002xlrPmdT"
);
export type AppProps = {};

const App: React.FC<AppProps> = (): JSX.Element => {
  const { isInitialized } = useAuth();
  const validation = useContext(ValidationContext);
  const appearance = {
    theme: "stripe",
    variables: {
      colorPrimary: "#0570de !important",
      colorBackground: "red !important",
      colorText: "#30313d !important",
      colorDanger: "#df1b41 !important",
      fontFamily: "Ideal Sans, system-ui, sans-serif !important",
      spacingUnit: "2px !important",
      borderRadius: "4px !important",
    },
  };
  return (
    <Provider store={store}>
      <ValidationContext.Provider value={validation}>
        <Elements stripe={stripePromise}>
          <ThemeProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <SnackbarProvider>
                <CssBaseline />
                <div className="content-wrap">
                  {isInitialized ? <Router /> : <Splash />}
                  <Toaster position="top-center" />
                </div>
              </SnackbarProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </Elements>
      </ValidationContext.Provider>
    </Provider>
  );
};

export default App;
