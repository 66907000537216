import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';

import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { CssBaseline, GlobalStyles, Theme } from '@mui/material';
import { globalStyles } from './globalStyles';
import { getTheme } from './themes';
import { ThemeName } from './types';

// Context
type ThemeContextProps = {
  currentTheme: Theme;
  setTheme?: (name: ThemeName) => void;
};
const initialThemeName: ThemeName = process.env.REACT_APP_THEME as ThemeName || 'light';


const initialContext: ThemeContextProps = {
  currentTheme: getTheme(initialThemeName)
};
const ThemeContext = createContext<ThemeContextProps>(initialContext);

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

type ThemeProviderProps = { children: ReactNode };
export const ThemeProvider: FC<ThemeProviderProps> = ({
  children
}): JSX.Element => {
  const [theme, setTheme] = useState<Theme>(initialContext.currentTheme);

  const _setTheme = useCallback((theme: ThemeName): void => {
    setTheme(getTheme(theme));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contextValue = useMemo<ThemeContextProps>(() => {
    return {
      currentTheme: theme,
      setTheme: _setTheme
    };
  }, [theme, _setTheme]);

  return (
    <ThemeContext.Provider value={contextValue}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        <GlobalStyles styles={globalStyles} />
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};
