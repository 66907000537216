
import httpClient from '../../core/helpers/HttpClient';
import { GlobalConf } from '../../conf/globalConfig';

import { ListContent as TPagedContent } from '../../model/ListContent';
import { TUser } from '../../model/User';

const getUsers = async (entityId: number, page: number, size: number): Promise<TPagedContent<TUser>> => {
  const response: TPagedContent<TUser> = await httpClient.get<TPagedContent<TUser>>(
    GlobalConf.api.users.all(entityId),
    { page, size }
  );
  return response;
};

const getUser = async (entityId: number, userId: string): Promise<TUser> => {
  const response: TUser = await httpClient.get<TUser>(
    GlobalConf.api.users.one(entityId, userId),
  );
  return response;
};

const deleteUser = async (entityId: number, userId: string): Promise<void> => {
  return await httpClient.delete<void>(
    GlobalConf.api.users.one(entityId, userId),
  );
};

export { getUsers, getUser, deleteUser };
