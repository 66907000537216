import { Components, Theme } from '@mui/material';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    lightGrey: true;
    darkGrey: true;
  }
}

export default function Button(theme: Theme): Components {
  return {
    
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: 'none',
          },
        },
        sizeLarge: {
          height: 48,
        },
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.shadows[10],
          '&:hover': {
            backgroundColor: theme.palette.grey[400],
          },
        },
        containedPrimary: {
          boxShadow: theme.shadows[10],
        },
        containedSecondary: {
          boxShadow: theme.shadows[10],
        },
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500]}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
}
