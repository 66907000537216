import { Theme, createTheme } from '@mui/material';
import { LightTheme } from './light/LightTheme';
import { DarkTheme } from './dark/DarkTheme';
import { ThemeName } from '../types';

export const themes: { [key: string]: Theme } = {
  light: LightTheme(),
  dark: createTheme(DarkTheme())
};

export const getTheme = (theme: ThemeName): Theme => themes[theme];
