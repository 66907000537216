import {
  call,
  put,
  all,
  takeLatest
} from 'redux-saga/effects';
import { getUsers, getUser, deleteUser } from '../../apis/users/users.api';
import { TUser } from '../../model/User';
import { ListContent } from '../../model/ListContent';
import { USERS } from './users.actions';
import { usersActions } from './users.actions';



type GetActionType = {
  type: string;
  meta: {
    entityId: number;
    page: number;
    size: number;
  }
};

type GetOneActionType = {
  type: string;
  meta: {
    entityId: number;
    userId: string;
  }
};

type DeleteActionType = {
  type: string;
  meta: {
    entityId: number;
    userId: string;
  }
};

export function* watchGetUsers(action: GetActionType) {
  try {
    const { meta: { entityId, page, size } } = action;
    const response: ListContent<TUser> = yield call(getUsers, entityId, page, size);
    yield put(usersActions.GET.success({ data: response }));
  } catch (error) {
    yield put(usersActions.GET.failure({ error }));
  }
}

export function* watchGetUser(action: GetOneActionType) {
  try {
    const { meta: { entityId, userId } } = action;
    const response: TUser = yield call(getUser, entityId, userId);
    yield put(usersActions.GET_ONE.success({ data: response }));
  } catch (error) {
    yield put(usersActions.GET_ONE.failure({ error }));
  }
}


export function* usersSagaWatcher(): Generator {
  yield all([takeLatest(USERS.GET.REQUEST, watchGetUsers)]);
  yield all([takeLatest(USERS.GET_ONE.REQUEST, watchGetUser)]);
}
