import { Theme } from '@mui/material/styles';
import { Components } from '@mui/material/styles/components';

export default function Divider(theme: Theme): Components {
  return {
    MuiDivider: {
      styleOverrides: {
        vertical: {
          // marginRight: 10,
          // marginLeft: 10
        },
        middle: {
          //marginTop: 10,
          //marginBottom: 10
          //width: '80%'
        }
      }
    }
  };
}
