import { createAction } from '@reduxjs/toolkit';

export const setFullScreenAction = createAction<boolean>(
  'layout/setFullScreenAction'
);
export const setSidebarAction = createAction<boolean>(
  'layout/setSidebarAction'
);
export const setHideHeaderAction = createAction<boolean>(
  'layout/setHideHeaderAction'
);
