import { Theme } from '@mui/material/styles';
import { Components } from '@mui/material/styles/components';

export default function Tabs(theme: Theme): Components {
  return {
    MuiTab: {
      defaultProps: {
        color: 'inherit',
      },
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
          fontSize: '1rem',
          textTransform: 'none',
          fontWeight: 400,
          padding: '.9em',
          minWidth: 'auto',
          minHeight: '62px',
        },
        textColorInherit: {
          opacity: 1,
        },
      }
    }
  };
}
