import React, { MouseEventHandler, useState } from "react";
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from "react-router-dom";
// material
import { alpha, useTheme, styled } from "@mui/material/styles";
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";
//
import Iconify from "./Iconify";

// ----------------------------------------------------------------------

const ListItemStyle = styled(ListItemButton)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: "inherit",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

// ----------------------------------------------------------------------

interface NavItemProps {
  item: {
    title: string;
    path: string;
    icon?: React.ReactNode;
    info?: React.ReactNode;
    children?: {
      title: string;
      hashtag: string;
      iconChildren?: React.ReactNode;
    }[];
  };
  active: (path: string) => boolean;
  onCloseSidebar: () => void;
}

function NavItem({ item, active, onCloseSidebar }: NavItemProps) {
  const theme = useTheme();
  const { title, path, icon, info, children } = item;

  const [open, setOpen] = useState(true);
  // Use effect to handle hash change and component re-render

  const handleModule = (titre: any) => {
    window.location.hash = "#" + titre;
    onCloseSidebar();
  };

  const isActiveRoot = active(path);

  const activeRootStyle = {
    color: "primary.main",
    fontWeight: "fontWeightMedium",
    bgcolor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
  };

  return (
    <>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding >
          {isActiveRoot &&
            activeRootStyle &&
            children &&
            children.map((child) => {
              const { title, iconChildren, hashtag } = child;
              const isCurrentHash = window.location.hash === `#${hashtag}`;
              return (
                <ListItemStyle 
                  key={hashtag}
                  onClick={() => handleModule(hashtag)}
                  sx={{
                    ...(isActiveRoot && activeRootStyle),
                    pt: 4, pb:4, pl:0, pr:2, fontSize:18,
                    '&:hover': {
                      backgroundColor: '#7F8C8D',
                      cursor: 'pointer',
                      opacity: 1
                    }, 
                    backgroundColor: isCurrentHash ? '#7F8C8D' : '#ECF0F1',
                  }}
                >
                  <ListItemIconStyle
                    sx={{
                      fontWeight: "900",
                      borderRadius: "0.375rem",
                      cursor: "pointer",
                      transition: "background-color 0.5s ease",
                      pl:0, pr:0
                    }}
                  >
                    {iconChildren && iconChildren}
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              );
            })}
        </List>
      </Collapse>
    </>
  );
}

interface NavSectionProps {
  navConfig: {
    title: string;
    path: string;
    icon?: React.ReactNode;
    info?: React.ReactNode;
    children?: {
      title: string;
      hashtag: string;
    }[];
  }[];
  onCloseSidebar: () => void;
}

export default function NavSection({ navConfig, onCloseSidebar }: NavSectionProps) {
  const { pathname } = useLocation();
  const match = (path: string) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;

  return (
    <Box>
      <List disablePadding sx={{ p: 1  }}>
        {navConfig.map((item) => (
          <NavItem key={item.title} item={item} active={match} onCloseSidebar={onCloseSidebar}  />
        ))}
      </List>
    </Box>
  );
}