/* Url des WS authentifications */
export const API_AUTH_URL = process.env.REACT_APP_API_AUTH_URL;

export const ACCESS_TOKEN = 'access_token';
export const REFRESH_TOKEN = 'refresh_token';
export const AUTH_AUTHENTICATE = '/api/v1/auth/authenticate';
export const AUTH_REGISTER = '/api/v1/auth/register';
export const AUTH_CONFIRME_EMAIL_CONNEXION =
    '/api/v1/auth/confirmEmailConnexion';
export const REACT_GOOGLE_MAP_API_KEY =
    'AIzaSyCN3Yp4oUd-eJzJb1zcsKoZEYtZlgcb60s';

export const AUTH_FORGOT_PASSWORD = '/api/v1/auth/motPassOublie';
export const AUTH_RESET_PASSWORD = '/api/v1/auth/motPassOublie/reset';
export const AUTH_USER_CONNECTED = '/api/v1/auth/utilisateurConnecte';
export const USER_DETAILS = 'userDetails';
export const AUTH_REFRESH_TOKEN = '/api/v1/auth/refresh-token';
export const CONTACT_POST_MESSAGE = '/api/v1/contact/message';

export const CONTACT_NEWS_LETTER = '/api/v1/contact/newsletter';

/*Url des suivis */
export const API_SUIVI_URL = process.env.REACT_APP_API_CHANTIER_URL;
export const SUIVIS_GET_ALL = '/api/v1/chantiers/suivis/list';
export const SUIVI_ADD = '/api/v1/chantiers/suivis/save';
export const SUIVI_UPDATE = '/api/v1/chantiers/suivis/modifierSuivi';
export const SUIVI_DELETE = '/api/v1/chantiers/suivis/delete';
export const SUIVI_GET_DETAIL = '/api/v1/chantiers/suivis/get';



/** Url Utilisateur */
export const UTILISATEUR_URL = process.env.REACT_APP_API_AUTH_URL;
export const EDIT_USER_PROFILE = '/api/v1/utilisateur/editprofile';
export const CHANGE_PASSWORD = '/api/v1/users/changepassword';
export const UPGRADE_ACCOUNT_URL = '/api/v1/utilisateur/upgrade-account';


export const OAUTH2_REDIRECT_URI = 'http://localhost:3000/oauth2/redirect';

export const GOOGLE_AUTH_URL =
    API_AUTH_URL +
    '/oauth2/authorize/google?redirect_uri=' +
    OAUTH2_REDIRECT_URI;
export const FACEBOOK_AUTH_URL =
    API_AUTH_URL +
    '/oauth2/authorize/facebook?redirect_uri=' +
    OAUTH2_REDIRECT_URI;
export const GITHUB_AUTH_URL =
    API_AUTH_URL +
    '/oauth2/authorize/github?redirect_uri=' +
    OAUTH2_REDIRECT_URI;

/* Url des WS Chantiers  */
export const API_CHANTIER_URL = process.env.REACT_APP_API_CHANTIER_URL;
export const CHANTIER_ADD = '/api/v1/chantiers/save';
export const CHANTIER_GET_ALL = '/api/v1/chantiers/list';
export const CHANTIER_UPDATE = '/api/v1/chantiers/modifierChantier';
export const CHANTIER_GET_DETAIL = '/api/v1/chantiers/get';

export const API_PLANNING_URL= process.env.REACT_APP_API_CHANTIER_URL;
export const PLANNING_CHANTIER_TACHES = '/api/v1/chantiers/plannings/chantier';
export const PLANNING_PERSONNEL_TACHES = '/api/v1/chantiers/plannings/personnel';
export const PLANNING_TACHE_ADD = '/api/v1/chantiers/plannings/tache/creer';
export const PLANNING_TACHE_UPDATE = '/api/v1/chantiers/plannings/tache/update';
export const PLANNING_TACHE_UPDATE_DATE = '/api/v1/chantiers/plannings/tache/updatedate';
export const PLANNING_TACHE_DELETE = '/api/v1/chantiers/plannings/tache/delete';

/*Url des Personneles */
export const API_PERSONNEL_URL = process.env.REACT_APP_API_CHANTIER_URL;
export const PERSONNEL_GET_ALL = '/api/v1/chantiers/personnel/list';
export const PERSONNEL_GET_NO_AFFECT_BY_CHANTIER = '/api/v1/chantiers/personnel/listPersoNonAffecter';
export const PERSONNEL_ADD = '/api/v1/chantiers/personnel/save';
export const PERSONNEL_UPDATE = '/api/v1/chantiers/personnel/modifierPersonnel';

/*Url des fournisseur */
export const API_FOURNISSEUR_URL = process.env.REACT_APP_API_CHANTIER_URL;
export const FOURNISSEURS_GET_ALL = '/api/v1/chantiers/fournisseurs/list';
export const FOURNISSEUR_ADD = '/api/v1/chantiers/fournisseurs/save';
export const FOURNISSEUR_UPDATE =
    '/api/v1/chantiers/fournisseurs/modifierFournisseur';
export const FOURNISSEUR_DELETE = '/api/v1/chantiers/fournisseurs/delete';
export const FOURNISSEUR_GET_DETAIL = '/api/v1/chantiers/fournisseurs/get';

/* Utls CRA */
export const API_CRA_URL = process.env.REACT_APP_API_CHANTIER_URL;
export const CRA_GET = '/api/v1/chantiers/cra/get';
export const CRA_ADD = '/api/v1/chantiers/cra/ajouter';
export const CRA_GET_MONTH = '/api/v1/chantiers/cra/list';

/*Url PersonnelChantier*/
export const API_PERSONNELCHANTIER_URL = process.env.REACT_APP_API_CHANTIER_URL;
export const PERSONNELCHANTIER_GET_BY_CHANTIER = '/api/v1/chantiers/personnelchantier/list';
export const PERSONNELCHANTIER_GET_BY_ID = '/api/v1/chantiers/personnelchantier/listPersonnelChantier';
export const PERSONNELCHANTIER_ADD = '/api/v1/chantiers/personnelchantier/save';
export const PERSONNELCHANTIER_ADD_ALL = '/api/v1/chantiers/personnelchantier/saveAll';

/*Type Document*/
export const API_TYPEDOCUMENT_URL = process.env.REACT_APP_API_CHANTIER_URL;
export const TYPEDOCUMENT_ADD = '/typedocument/ajoutertypedocument';
export const TYPEDOCUMENT_GET_ALL = '/typedocument/list';

/* Url des Documents  */
export const API_DOCUMENT_URL = process.env.REACT_APP_API_CHANTIER_URL;
export const DOCUMENT_GET_ALL = '/api/v1/chantiers/document/list';
export const DOCUMENT_GET_ONE = '/api/v1/chantiers/document/get';
export const DOCUMENT_ADD = '/api/v1/chantiers/document/ajouterdocument';
export const DOCUMENT_UPDATE = '/api/v1/chantiers/document/modifierDocument';
export const DOCUMENT_FILTER='/api/v1/chantiers/document/filter'

export const API_DEPENSE_URL = process.env.REACT_APP_API_CHANTIER_URL;
export const DEPENSES_GET_ALL = '/api/v1/chantiers/depenses/list';
export const DEPENSE_ADD = '/api/v1/chantiers/depenses/save';
export const DEPENSE_UPDATE = '/api/v1/chantiers/depenses/modifierFournisseur';
export const DEPENSE_DELETE = '/api/v1/chantiers/depenses/delete';

export const API_RECETTE_URL = process.env.REACT_APP_API_CHANTIER_URL;
export const RECETTES_GET_ALL = '/api/v1/chantiers/recettes/list';
export const RECETTE_ADD = '/api/v1/chantiers/recettes/save';
export const RECETTE_UPDATE = '/api/v1/chantiers/recettes/modifierRecette';
export const RECETTE_DELETE = '/api/v1/chantiers/recettes/delete';
export const RECETTE_GET_DETAIL = '/api/v1/chantiers/recettes/get';

export const API_SERVICE_URL = process.env.REACT_APP_API_CHANTIER_URL;
export const SERVICES_GET_ALL = '/api/v1/chantiers/services/list';
export const SERVICE_ADD = '/api/v1/chantiers/services/save';
export const SERVICE_UPDATE = '/api/v1/chantiers/services/modifierService';
export const SERVICE_DELETE = '/api/v1/chantiers/services/delete';
export const SERVICE_GET_DETAIL = '/api/v1/chantiers/services/get';


/*Url Actualite */
export const API_ACTUALITE_URL = process.env.REACT_APP_API_CHANTIER_URL;
export const ACTUALITE_GET_ALL = '/api/v1/chantiers/actualite/list';
export const ACTUALITE_ANNOUNCEMENTS = '/api/v1/chantiers/actualite/announcements';

/*Url des Abonnements */
export const API_ABONNEMENT_URL = process.env.REACT_APP_API_AUTH_URL;
export const ABONNEMENTS_GET_ALL = '/api/v1/abonnement/list';
export const CREATE_ABONNEMENT = '/api/v1/abonnement/subscribe';
export const PAYMENT = '/api/v1/payment/subscription'



