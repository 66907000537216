export const createAction = (type: string, data = {}, metadata = {}) => {
  return { type, payload: data, meta: metadata };
};

export const apiActionCreactor = (type: any) => {
  return {
    request: (meta?: any, data?: any) => createAction(type.REQUEST, { loading: true, success: false, error: null, ...(data && data) }, meta),
    success: (data: any = {}) => {
      const { meta } = data;
      return createAction(
        type.SUCCESS,
        {
          ...data,
          loading: false,
          success: true,
          error: null,
        },
        meta,
      );
    },
    failure: (error: any) => createAction(type.FAILURE, { ...error, loading: false, success: false }),
  };
};


export const apiType = (entity: string) =>
  createActionTypes(entity, ['GET', 'GET_ONE', 'ADD', 'PUT', 'DELETE']);

export const createActionTypes = (base: string, actions: Array<string> = []) => {
  return actions.reduce((acc: any, type) => {
    const baseType = `${base}_${type}`;
    acc[type] = {
      REQUEST: `${baseType}_LOADING`,
      SUCCESS: `${baseType}_SUCCESS`,
      FAILURE: `${baseType}_FAILURE`,
    };
    return acc;
  }, {});
};

export const createActionType = (base: string) => {
  return {
    REQUEST: `${base}_LOADING`,
    SUCCESS: `${base}_SUCCESS`,
    FAILURE: `${base}_FAILURE`,
  };
};

export const apiActions = (entity: any) => ({
  GET: apiActionCreactor(entity.GET),
  GET_ONE: apiActionCreactor(entity.GET_ONE),
  ADD: apiActionCreactor(entity.ADD),
  PUT: apiActionCreactor(entity.PUT),
  DELETE: apiActionCreactor(entity.DELETE)
});
