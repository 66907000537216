// component
import Iconify from "../../common/Iconify";
import { ReactNode } from "react";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
// ----------------------------------------------------------------------

const getIcon = (name: string): ReactNode => (
  <Iconify icon={name} width={22} height={22} />
);

interface NavItem {
  title: string;
  path: string;
  icon: ReactNode;
  children?: {
    title: string;
    hashtag: string;
    path: string;
    iconChildren: ReactNode;
  }[];
}

const navConfig: NavItem[] = [
  {
    title: "Woo Construct ",
    path: "/dashboard",
    icon: getIcon("eva:pie-chart-2-fill"),
  },
  {
    title: "chantier",
    path: "/chantier/get/:id",
    icon: getIcon("eva:pie-chart-2-fill"),
    children: [
      {
        title: "Actualité",
        hashtag: "Actualite",
        path: "/actualite",
        iconChildren: getIcon("eva:bell-fill"),
      },
      {
        title: "Détail Chantier",
        hashtag: "Detail_chantier",
        path: "/detail_chantier",
        iconChildren: getIcon("eva:file-text-fill"),
      },
      {
        title: "Planning",
        hashtag: "Planning",
        path: "/planning",
        iconChildren: getIcon("eva:calendar-fill"),
      },
      {
        title: "Documents",
        hashtag: "Documents",
        path: "/documents",
        iconChildren: getIcon("eva:folder-fill"),
      },
      {
        title: "Rapport Activité",
        hashtag: "Rapport_Activite",
        path: "/rapport_activite",
        iconChildren: getIcon("eva:bar-chart-fill"),
      },
      {
        title: "Fiche Suivi",
        hashtag: "Fiche_de_Suivi",
        path: "/fiche_suivi",
        iconChildren: getIcon("eva:clipboard-outline"),
      },
      {
        title: "Finance",
        hashtag: "Finance",
        path: "/finance",
        iconChildren: getIcon("mdi:finance"),
      },
    ],
  },
  {
    title: "Setting",
    path: "/setting",
    icon: getIcon("eva:pie-chart-2-fill"),
    children: [
      {
        title: "Personnel",
        hashtag: "Personnel",
        path: "/personnel",
        iconChildren: getIcon("eva:people-fill"),
      },
      {
        title: "Fournisseurs",
        hashtag: "Fournisseurs",
        path: "/fournisseurs",
        iconChildren: getIcon("eva:people-fill"),
      },
      {
        title: "Rapport Activité",
        hashtag: "CRA_GLOBAL",
        path: "/cra_global",
        iconChildren: getIcon("eva:people-fill"),
      },
    ],
  },
  {
    title: "Rapport Activité Mensuel",
    path: "/all-cra-in-month",
    icon: <CalendarMonthOutlinedIcon />,
  },
  {
    title: "Abonnement ",
    path: "/abonnement",
    icon: <SubscriptionsIcon />,
  },
  {
    title: "blog",
    path: "/dashboard/app",
    icon: getIcon("eva:file-text-fill"),
  },
  {
    title: "login",
    path: "/login",
    icon: getIcon("eva:lock-fill"),
  },
  {
    title: "register",
    path: "/register",
    icon: getIcon("eva:person-add-fill"),
  },
  {
    title: "Not found",
    path: "/404",
    icon: getIcon("eva:alert-triangle-fill"),
  },
];

export default navConfig;
