import { Theme } from '@mui/material/styles';
import { Components } from '@mui/material/styles/components';

export default function Card(theme: Theme): Components {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: theme.shadows[12],
          borderRadius: theme.shape.borderRadius,
          position: 'relative',
          zIndex: 0
        }
      }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: 'h6' },
        subheaderTypographyProps: {
          variant: 'body2',
          marginTop: theme.spacing(0.5)
        }
      },
      styleOverrides: {
        root: {
          //padding: theme.spacing(3, 3, 0)
        },
        subheader: {
          fontSize: '0.875rem',
          color: theme.palette.text.disabled
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3)
        }
      }
    }
  };
}
