import {
  call,
  all,
  put,
  takeLatest
} from 'redux-saga/effects';

import { loadUserContext } from './user-context.actions';
import { contextActions } from './user-context.slice';

export function* watchLoadUserContext() {
  try {
  
    //yield put(contextActions.setContext({ ...response, ...stored }));

  } catch (error) {
    console.error('error: ', error);
  }
}

export function* userContextSageWatcher(): Generator {
  yield all([takeLatest(loadUserContext, watchLoadUserContext)]);
}
