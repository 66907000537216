import { createContext, useEffect, useReducer, ReactNode, FC } from "react";
import PropTypes from "prop-types";
import { authApi } from "../apis/authApi";
import { toast } from "react-hot-toast";
import { USER_DETAILS } from "../utils/ApiUrlConstants";
import { decodeToken } from "../utils/jwt_decode";

// Define the types for state and actions
interface User {
  email: string;
  name: string;
  image: string;
  role: string;
  userId: string;
}

interface State {
  isLoggedIn?: boolean;
  isInitialized?: boolean;
  user?: User | null;
}

type ActionType = "INITIALIZE" | "LOGIN_SUCCESS" | "LOGOUT";

interface Action {
  type: ActionType;
  payload?: any;
}

interface AuthContextProps extends State {
  platform: "JWT";
  login: (email: string, password: string) => Promise<void>;
  facebookLogin: (userID: string, accessToken: string) => Promise<void>;
  googleLogin: () => Promise<void>;
  logout: () => Promise<void>;
  register: (data: any) => Promise<void>;
  verifyEmail: (activationToken: string) => Promise<void>;
  resetPassword: (
    resetPasswordToken: string,
    password: string,
    confirmPassword: string
  ) => Promise<void>;
}

const initialState: State = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

const handlers: Record<ActionType, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: Action): State => {
    const { isLoggedIn, user } = action.payload;
    return {
      ...state,
      isLoggedIn,
      isInitialized: true,
      user,
    };
  },
  LOGIN_SUCCESS: (state: State, action: Action): State => {
    const { user } = action.payload;
    return {
      ...state,
      isLoggedIn: true,
      user,
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isLoggedIn: false,
    user: null,
  }),
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextProps>({
  ...initialState,
  platform: "JWT",
  login: () => Promise.resolve(),
  facebookLogin: () => Promise.resolve(),
  googleLogin: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  verifyEmail: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const userDetails = JSON.parse(
          localStorage.getItem(USER_DETAILS) || "null"
        );

        if (userDetails) {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isLoggedIn: true,
              user: userDetails,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isLoggedIn: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error("JWT Context: " + err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isLoggedIn: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    try {
      const res = await authApi.login({ email, password });
      const user = decodeToken(res);
      if (user) {
        localStorage.setItem(USER_DETAILS, JSON.stringify(user));
        dispatch({
          type: "LOGIN_SUCCESS",
          payload: { user },
        });
      }
    } catch (error) {
      console.error("Error in login:", error);
    }
  };

  const verifyEmail = async (activationToken: string) => {
    try {
      const res = await authApi.emailverification(activationToken);
      const user = decodeToken(res);
      if (user) {
        localStorage.setItem(USER_DETAILS, JSON.stringify(user));
        dispatch({
          type: "LOGIN_SUCCESS",
          payload: { user },
        });
      }
    } catch (error) {
      console.error("Error in verifyEmail:", error);
    }
  };

  const resetPassword = async (
    resetPasswordToken: string,
    password: string,
    confirmPassword: string
  ) => {
    try {
      const res = await authApi.resetpassword({
        resetPasswordToken,
        password,
        confirmPassword,
      });
      return res;
      // const user = decodeToken(res);
      // console.log("user : " + JSON.stringify(user));
      // if (user) {
      //   localStorage.setItem(USER_DETAILS, JSON.stringify(user));
      //   dispatch({
      //     type: "LOGIN_SUCCESS",
      //     payload: { user },
      //   });
      // }
    } catch (error) {
      console.error("Error in resetPassword:", error);
    }
  };

  const facebookLogin = async (userID: string, accessToken: string) => {
    try {
      const res = await authApi.facebookLogin({ userID, accessToken });
      const user = decodeToken(res);
      if (user) {
        localStorage.setItem(USER_DETAILS, JSON.stringify(user));
        dispatch({
          type: "LOGIN_SUCCESS",
          payload: { user },
        });
      }
    } catch (error) {
      console.error("Error in facebookLogin:", error);
    }
  };

  const googleLogin = async () => {
    try {
      const res = await authApi.refresh();
      const user = decodeToken(res);
      if (user) {
        localStorage.setItem(USER_DETAILS, JSON.stringify(user));
        dispatch({
          type: "LOGIN_SUCCESS",
          payload: { user },
        });
      }
    } catch (error) {
      console.error("Error in googleLogin:", error);
    }
  };

  const register = async (data: any) => {
    try {
      const res = await authApi.signup(data);
      if (res.success) {
        toast.success(res.message);
      }
    } catch (error) {
      console.error("Error in register:", error);
    }
  };

  const logout = async () => {
    try {
      await authApi.logout();
      localStorage.removeItem(USER_DETAILS);
      dispatch({ type: "LOGOUT" });
    } catch (err) {
      console.error("Error in logout:", err);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: "JWT",
        login,
        logout,
        register,
        facebookLogin,
        googleLogin,
        verifyEmail,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
