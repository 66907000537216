import { ThemeOptions } from '@mui/material';
import { themeConfig } from '../../conf/globalConfig';
import typography from '../shared/typography';

const sharedTheme = {
  palette: {
    background: {
      default: '#fff',
      paper: '#fff'
    }
  },
  typography,
  header: {
    height: `${themeConfig.webHeaderHeight}px`
  },
  sidebar: {
    width: `${themeConfig.sideBarWidth}px`
  }
} as ThemeOptions; // the reason for this casting is deepmerge return type

export default sharedTheme;
