import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

export const globalStyles = (
  theme: Theme
): { [key: string]: SxProps<Theme> } => ({
  body: {
    backgroundColor: theme.palette.background.default,
  },
  '.page-mode-view': {
    '.MuiFormLabel-root.Mui-disabled': {
      color: theme.palette.text.primary,
    },
    '.MuiInputBase-root.Mui-disabled': {
      background: 'none',
      border: 'none',
    },
    '.MuiInputBase-root.Mui-disabled input': {
      fontWeight: 500,
      textTransform: 'uppercase',
      WebkitTextFillColor: theme.palette.text.primary,
    },
  },
  '.sidebar-menu': {
    '.sidebar-menu-item': {
      '.smi-title': {},
      '.smi-icon svg > *': {},
    },
  },
  '.a-text-editor': {
    border: 1,
    borderColor: theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
    borderStyle: 'solid',
    display: 'flex',
    flexDirection: 'column',

    '& .ql-snow.ql-toolbar': {
      borderColor: theme.palette.divider,
      borderLeft: 'none',
      borderRight: 'none',
      borderTop: 'none',
      '& .ql-picker-label:hover': {
        color: theme.palette.primary.main,
      },
      '& .ql-picker-label.ql-active': {
        color: theme.palette.primary.main,
      },
      '& .ql-picker-item:hover': {
        color: theme.palette.primary.main,
      },
      '& .ql-picker-item.ql-selected': {
        color: theme.palette.primary.main,
      },
      '& button:hover': {
        color: theme.palette.primary.main,
        '& .ql-stroke': {
          stroke: theme.palette.primary.main,
        },
      },
      '& button:focus': {
        color: theme.palette.primary.main,
        '& .ql-stroke': {
          stroke: theme.palette.primary.main,
        },
      },
      '& button.ql-active': {
        '& .ql-stroke': {
          stroke: theme.palette.primary.main,
        },
      },
      '& .ql-stroke': {
        stroke: theme.palette.text.primary,
      },
      '& .ql-picker': {
        color: theme.palette.text.primary,
      },
      '& .ql-picker-options': {
        backgroundColor: theme.palette.background.paper,
        border: 'none',
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[10],
        padding: theme.spacing(2),
      },
    },
    '& .ql-snow.ql-container': {
      height: '240px',
      borderBottom: 'none',
      borderColor: theme.palette.divider,
      borderLeft: 'none',
      borderRight: 'none',
      flexGrow: 1,
      '& .ql-editor': {
        color: theme.palette.text.primary,
        fontFamily: theme.typography.body1.fontFamily,
        fontSize: theme.typography.body1.fontSize,
        padding: theme.spacing(2),
        '&.ql-blank::before': {
          color: theme.palette.text.secondary,
          fontStyle: 'normal',
          left: theme.spacing(2),
        },
      },
    },
  },
  '.MuiTextField-root': {
    '& .MuiInputBase-root': {
      color: theme.palette.text.primary,
      '&.Mui-disabled': {
        color: theme.palette.text.disabled,
      },
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.text.primary,
      '&.Mui-focused': {
        color: theme.palette.primary.main,
      },
      '&.Mui-disabled': {
        color: theme.palette.text.disabled,
      },
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.divider,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.light,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&.Mui-disabled fieldset': {
        borderColor: theme.palette.divider,
      },
    },
    '& .MuiFormHelperText-root': {
      color: theme.palette.text.secondary,
      '&.Mui-error': {
        color: theme.palette.error.main,
      },
    },
  },
});
