import React from 'react';

const Splash: React.FC = () => {
    return (
        <div className='graf-bg-container'>
            <div className='graf-layout'>
                <div className='graf-circle'></div>
                <div className='graf-circle'></div>
                <div className='graf-circle'></div>
                <div className='graf-circle'></div>
                <div className='graf-circle'></div>
                <div className='graf-circle'></div>
                <div className='graf-circle'></div>
                <div className='graf-circle'></div>
                <div className='graf-circle'></div>
                <div className='graf-circle'></div>
                <div className='graf-circle'></div>
            </div>
        </div>
    );
};

export default Splash;
