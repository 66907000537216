import React from 'react';
import PropTypes from 'prop-types';
// icons
import { Icon } from '@iconify/react';
// @mui
import { Box, SxProps, Theme } from '@mui/material';

// ----------------------------------------------------------------------

interface IconifyProps {
  icon: string; // iconify icons are expected to be strings
  sx?: SxProps<Theme>;
  [other: string]: any;
}

const Iconify: React.FC<IconifyProps> = ({ icon, sx, ...other }) => {
  return <Box component={Icon} icon={icon} sx={sx} {...other} />;
};

Iconify.propTypes = {
  icon: PropTypes.string.isRequired,
  sx: PropTypes.object,
};

export default Iconify;
