import React, { useState } from "react";
import { authApi } from "../../apis/authApi";
import {
  TextField,
  Button,
  Grid,
  DialogContent,
  Dialog,
  DialogTitle,
  Theme,
  IconButton,
  InputAdornment,
} from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import { makeStyles } from "@mui/styles"; // Importer makeStyles pour définir des styles
import { VisibilityOff, Visibility } from "@mui/icons-material";
import CustomButton from "../../common/components/ui/CustomButton";

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  titleStyle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    textAlign: "center",
  },
  buttonStyle: {
    variant: "contained",
    color: theme.palette.common.white + " " + "!important",
    backgroundColor: theme.palette.primary.main + " " + "!important",
  },
  dialogContentStyle: {
    p: 2,
    width: "100%",
  },
  textFieldsContainer: {
    marginTop: "0.1em !important",
  },
}));

interface ChangePasswordFormProps {
  isOpen: boolean;
  handleClose: () => void;
}

function ChangePasswordForm({ isOpen, handleClose }: ChangePasswordFormProps) {
  const classes = useStyles();
  const [requestPassword, setRequestPassword] = useState({
    currentPassword: "",
    newPassword: "",
    confirmationPassword: "",
  });
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };
  const handleClickShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setRequestPassword((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const response = await authApi.changePassword(requestPassword);
      toast.success(response);
    } catch (error: any) {
      console.log(error);

      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error(
          "Une erreur technique est survenue. Veuillez réessayer plus tard."
        );
      }
      console.error("Erreur lors de la modification du mot de passe :", error);
    }

    // Réinitialiser les champs du formulaire
    setRequestPassword({
      currentPassword: "",
      newPassword: "",
      confirmationPassword: "",
    });
    console.log("password changed");
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle className={classes.titleStyle}>
        Changement de Mot de Passe
      </DialogTitle>
      <DialogContent className={classes.dialogContentStyle}>
        <form onSubmit={handleSubmit} className={classes.form}>
          <Grid container spacing={2} className={classes.textFieldsContainer}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type={showPassword1 ? "text" : "password"}
                label="Mot de Passe Actuel"
                variant="outlined"
                name="currentPassword"
                value={requestPassword.currentPassword}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={`toggle Mot de Passe Actuel visibility`}
                        onClick={handleClickShowPassword1}
                        edge="end"
                      >
                        {showPassword1 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type={showPassword2 ? "text" : "password"}
                label="Nouveau Mot de Passe"
                name="newPassword"
                variant="outlined"
                value={requestPassword.newPassword}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={`toggleNouveau Mot de Passe visibility`}
                        onClick={handleClickShowPassword2}
                        edge="end"
                      >
                        {showPassword2 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type={showPassword2 ? "text" : "password"}
                label="Confirmation du Mot de Passe"
                name="confirmationPassword"
                variant="outlined"
                value={requestPassword.confirmationPassword}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={`toggle Confirmation du Mot de Passe visibility`}
                        onClick={handleClickShowPassword2}
                        edge="end"
                      >
                        {showPassword2 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomButton
                type="submit"
                fullWidth
                className={classes.buttonStyle}
                disabled={
                  !requestPassword.currentPassword ||
                  !requestPassword.newPassword ||
                  !requestPassword.confirmationPassword ||
                  requestPassword.newPassword !==
                    requestPassword.confirmationPassword
                }
              >
                Mettre à Jour
              </CustomButton>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default ChangePasswordForm;
