import { Theme } from '@mui/material/styles';
import { merge } from 'lodash';

import Typography from './Typography';
import Button from './Button';
import Divider from './Divider';
import Breadcrumbs from './Breadcrumbs';
import DataGrid from './DataGrid';
import Stepper from './Stepper';
import Input from './Input';
import Tabs from './Tabs';
import Card from './Card';
import Switch from './Switch';

export default function ComponentsOverrides(theme: Theme) {
  return merge(
    Button(theme),
    Divider(theme),
    Typography(theme),
    Breadcrumbs(theme),
    DataGrid(theme),
    Stepper(theme),
    Input(theme),
    Switch(theme),
    Tabs(theme),
    Card(theme)
  );
}
