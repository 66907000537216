import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box, SxProps, Theme } from '@mui/material';

// Define the types for the props
interface LogoProps {
  disabledLink?: boolean;
  sx?: SxProps<Theme>;
}

// Set default props using TypeScript's default parameter feature
const Logo: React.FC<LogoProps> = ({ disabledLink = false, sx }) => {
  const logoSrc = process.env.REACT_APP_THEME === 'dark' ? '/static/nav-logo-marocbtp.png' : '/static/nav-logo.png';
  const logo = <Box component="img" src={logoSrc} sx={{ width: 100, height: 80, ...sx }} />;

  if (disabledLink) {
    return <React.Fragment>{logo}</React.Fragment>;
  }

  return <RouterLink to="/dashboard">{logo}</RouterLink>;
};

// Add propTypes for backward compatibility if needed
Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;