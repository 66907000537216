import {jwtDecode as jwtDecode} from 'jwt-decode';

interface DecodedToken {
    email: string;
    name: string;
    image: string;
    role: string;
    userId: string;
    exp?: number; // optional, depending on the JWT
}

export const decodeToken = (token: string): DecodedToken | null => {
    try {
        const decodedToken: DecodedToken = jwtDecode(token);
        const { email, name, image, role, userId } = decodedToken;

        return {
            email,
            name,
            image,
            role,
            userId,
        };
    } catch (error) {
        console.error('Error decoding JWT:', error);
        return null;
    }
};
