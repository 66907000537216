import {
  delay,
  put,
  Effect,
  takeLatest,
  all
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { authActions } from './auth.slice';
import { checkandFillAuth } from './auth.actions';

export function* watchSetConnectedUser(
  action: PayloadAction<any>
): Generator<Effect, void> {
  try {
    yield delay(1000);
    yield put(authActions.setConnectedUser({ username: 'Mohamed' }));
  } catch (error) {
    console.error('error: ', error);
  }
}

export function* authSagaWatcher(): Generator {
  yield all([takeLatest(checkandFillAuth, watchSetConnectedUser)]);
}
