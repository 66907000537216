import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isMobileScreen } from '../../core/helpers/utilities';
import { TLayoutState } from '../../model/Layout';

const initialState: TLayoutState = {
  openSidebar: !isMobileScreen(),
  hideHeader: false
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setFullScreen: (state, action: PayloadAction<boolean>) => {
      state.openSidebar = !action.payload;
      state.hideHeader = action.payload;
    },
    setOpenSidebar: (state, action: PayloadAction<boolean>) => {
      state.openSidebar = action.payload;
    },
    setHideHeader: (state, action: PayloadAction<boolean>) => {
      state.hideHeader = action.payload;
    }
  }
});

export const layoutActions = layoutSlice.actions;
export default layoutSlice.reducer;
