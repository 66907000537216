import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TAuth } from '../../model/Auth';
import { TConnectedUser } from '../../model/User';

const initialState: TAuth = {
  connectedUser: undefined
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setConnectedUser: (state, action: PayloadAction<TConnectedUser>) => {
      state.connectedUser = action.payload;
    }
  }
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
