import axios, { AxiosInstance } from 'axios';

import { API_AUTH_URL } from '../utils/ApiUrlConstants';
import { AuthApi } from './AuthApi.1';

export const axiosInstance: AxiosInstance = axios.create({
    baseURL: API_AUTH_URL,
    withCredentials:true,
    headers: {
        'Content-Type': 'application/json',
    },
});

export const authApi: AuthApi = new AuthApi();
