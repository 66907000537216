import React from "react";
import PropTypes from "prop-types";
// material
import { alpha, styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, IconButton } from "@mui/material";
// components
import Iconify from "../../common/Iconify";
import AccountPopover from "./AccountPopover";
import LanguagePopover from "./LanguagePopover";
import NotificationsPopover from "./NotificationsPopover";
import Logo from "../../common/Logo";
import MenuBar from "../MenuBar";
import { styled as styledComponents } from "styled-components";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 240;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start", // align items to the start (left)
  },
}));

const CustomDivWrapper = styledComponents.div`
  .MuiToolbar-root  {
    padding: 0px 0px;
  }
`;

// ----------------------------------------------------------------------

interface DashboardNavbarProps {
  onOpenSidebar: () => void;
}

const DashboardNavbar: React.FC<DashboardNavbarProps> = ({ onOpenSidebar }) => {
  return (
    <>
      <CustomDivWrapper>
        <RootStyle sx={{ pl: 0, pr: 1 }}>
          <ToolbarStyle>
            <IconButton
              onClick={onOpenSidebar}
              sx={{
                ml: 1,
                fontSize: 48,
                color: "text.primary",
                display: { lg: "none" },
              }}
            >
              <Iconify icon="eva:menu-2-fill" />
            </IconButton>
            <Box sx={{ mt: 0, ml: 0 }}>
              <Logo />
            </Box>

            <Box sx={{ flexGrow: 1 }}>
              <Box
                sx={{
                  mr: 1,
                  color: "text.primary",
                  display: { xs: "none", sm: "none", lg: "block" },
                }}
              >
                <MenuBar />
              </Box>
            </Box>

            <Stack
              direction="row"
              alignItems="center"
              spacing={{ xs: 0.5, sm: 1.5 }}
            >
              <LanguagePopover />
              <NotificationsPopover />
              <AccountPopover />
            </Stack>
          </ToolbarStyle>
        </RootStyle>
      </CustomDivWrapper>
    </>
  );
};

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func.isRequired,
};

export default DashboardNavbar;
