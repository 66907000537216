import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { makeStyles } from "@mui/styles";
import { authApi } from "../../apis/authApi";
import {
  Grid,
  Card,
  Typography,
  TextField,
  Dialog,
  DialogContent,
  useTheme,
  Theme,
  Paper,
  IconButton,
  Divider,
  Avatar,
  Snackbar,
} from "@mui/material";
import { TConnectedUser } from "../../model/User";
import { Edit } from "@mui/icons-material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CustomButton from "../../common/components/ui/CustomButton";

interface UserProfileData {
  firstname: string;
  lastname: string;
  numTelephone: string;
  photoProfile?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    width: "8em",
    marginTop: "2px",
    color: theme.palette.primary.main,
  },
  dialogContainer: {
    maxWidth: "800px",
    margin: "auto",
    backgroundColor: "transparent !important",
    boxShadow: "none",
  },
  dialogContentContainer: {
    backgroundColor: "transparent !important",
    padding: 0 + " !important",
    maxHeight: "550px",
    height: "400px",
  },
  paperContainer: {
    background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.main} 90%)`,
    textAlign: "center",
    borderTopLeftRadius: ".5rem",
    borderBottomLeftRadius: ".5rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    margin: 0,
    paddingTop: "4em",
    paddingBottom: "4em",
  },
  paperContainer2: {
    height: "100%",
    width: "100%",
    margin: 0,
    padding: 0,
    borderTopRightRadius: ".5rem",
    borderBottomRightRadius: ".5rem",
  },
  card: {
    height: "100%",
    width: "100%",
    backgroundColor: "transparent !important",
  },
  title: {
    textAlign: "center",
    color: theme.palette.primary.main,
  },
  divider: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
  },
  cardContainer: { height: "100%", width: "100%" },
  columnContainer2: {
    height: "100%",
    width: "100%",
    backgroundColor: "transparent !important",
  },
  columnContainer1: {
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.primary.main + " !important",
    textAlign: "center",
  },
  formContainer: {
    height: "100%",
    width: "100%",
  },
  gridContainer: {
    padding: "0.4em",
    maxHeight: "5rem",
    marginBottom: theme.spacing(0.5) + " !important",
  },
  fieldContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.primary.main,
  },
  textField: {
    size: "0.875rem !important",
  },
  infos: {
    color: theme.palette.common.white,
  },
  inputContainer: {
    padding: "0.4em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    marginBottom: "0.4em",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  avatar: {
    width: "100px !important",
    height: "100px !important",
    marginX: "auto !important",
    marginBottom: "2px !important",
  },
}));

interface EditProfileProps {
  isOpen: boolean;
  onClose: () => void;
  connectedUser: TConnectedUser | null;
  fetchUserConnected: () => void;
}

export default function EditProfile({
  isOpen,
  onClose,
  connectedUser,
  fetchUserConnected,
}: EditProfileProps) {
  const theme = useTheme();
  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required("Le prénom est requis"),
    lastname: Yup.string().required("Le nom est requis"),
    numTelephone: Yup.string()
      .matches(/^\d+$/, "Le numéro de téléphone doit être un nombre")
      .required("Le numéro de téléphone est requis"),
  });
  const classes = useStyles();

  const [formData, setFormData] = useState<UserProfileData | null>(null);
  const [tempProfileData, setTempProfileData] = useState<UserProfileData>({
    firstname: "",
    lastname: "",
    numTelephone: "",
    photoProfile: "",
  });
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [photoProfile, setPhotoProfile] = useState<File | string>("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(true);
  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});
  const [decode, setDecode] = useState("data:image/jpeg;base64,");

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        if (connectedUser) {
          setFormData({
            firstname:
              connectedUser.firstname !== undefined
                ? connectedUser.firstname
                : "",
            lastname:
              connectedUser.lastname !== undefined
                ? connectedUser.lastname
                : "",
            numTelephone:
              connectedUser.telephone !== undefined
                ? connectedUser.telephone
                : "",
            photoProfile:
              connectedUser.photoProfile !== undefined
                ? connectedUser.photoProfile
                : "",
          });
          setTempProfileData({
            firstname:
              connectedUser.firstname !== undefined
                ? connectedUser.firstname
                : "",
            lastname:
              connectedUser.lastname !== undefined
                ? connectedUser.lastname
                : "",
            numTelephone:
              connectedUser.telephone !== undefined
                ? connectedUser.telephone
                : "",
            photoProfile:
              connectedUser.photoProfile !== undefined
                ? connectedUser.photoProfile
                : "",
          });
        } else {
          console.log("Connexion erreur !");
          toast.error("Connexion erreur!");
        }
      } catch (error: any) {
        console.error(error);
        toast.error(error);
      }
    };

    fetchUserDetails();
  }, [connectedUser]);

  const handleChangePhotoProfile = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile) {
      setPhotoProfile(selectedFile);
      setDecode("");
      const reader = new FileReader();
      reader.onload = (event) => {
        // Mise à jour de l'état avec l'image sélectionnée
        if (event.target)
          if (formData != null)
            setFormData({
              ...formData,
              photoProfile: event.target.result as string,
            });
      };
      setOpenToast(true);
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleSave = async () => {
    try {
      await validationSchema.validate(tempProfileData, {
        abortEarly: false,
      });
      setFormData(tempProfileData);
      setDecode("data:image/jpeg;base64,");
      const formData = new FormData();
      if (photoProfile) {
        formData.append("photoProfile", photoProfile);
      }
      //formData.append("profileData", JSON.stringify(formValues));
      formData.append(
        "profileData",
        new Blob([JSON.stringify(tempProfileData)], {
          type: "application/json",
        })
      );
      const userResponse = await authApi.editProfile(formData);
      if (userResponse) {
        console.log("userResponse: " + JSON.stringify(userResponse));
        toast.success("La modification est réussie");
        setShowSuccessAlert(true);
        fetchUserConnected();
      }
    } catch (error: any) {
      console.error(error);
      if (error.inner) {
        const errors = error.inner.reduce((acc: any, val: any) => {
          acc[val.path] = val.message;
          return acc;
        }, {});
        setValidationErrors(errors);
      } else {
        toast.error(
          "Une erreur technique est survenue. Veuillez réessayer plus tard.",
          {
            duration: 10000,
          }
        );
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setTempProfileData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleEdit = () => {
    setShowSuccessAlert(false);
  };

  const handleClose = () => {
    setShowSuccessAlert(true);
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="md"
      className={classes.dialogContainer}
    >
      <DialogContent className={classes.dialogContentContainer}>
        <Card className={classes.card}>
          <Grid container spacing={0} className={classes.cardContainer}>
            <Grid item md={4} className={classes.columnContainer1}>
              <Paper className={classes.paperContainer}>
                <Avatar
                  alt="avatar"
                  src={`${decode}${formData?.photoProfile}`}
                  className={classes.avatar}
                  htmlFor="photoProfile"
                  component="label"
                />
                <input
                  type="file"
                  name="photoProfile"
                  id="photoProfile"
                  className="inputstyle"
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={handleChangePhotoProfile}
                />

                <Snackbar
                  open={openToast}
                  autoHideDuration={6000} // Adjust duration as needed
                  onClose={() => setOpenToast(false)}
                  message={
                    <div>
                      <CheckCircleRoundedIcon />
                      Upload avec succès
                    </div>
                  }
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                />
                <Typography variant="body1" className={classes.infos}>
                  {formData?.firstname}{" "}
                </Typography>
                <Typography variant="body1" className={classes.infos}>
                  {formData?.lastname}
                </Typography>
                <Typography variant="body1" className={classes.infos}>
                  {formData?.numTelephone}
                </Typography>
                <IconButton className={classes.infos}>
                  <Edit className={classes.infos} onClick={handleEdit} />
                </IconButton>
              </Paper>
            </Grid>
            <Grid item md={8} className={classes.columnContainer2}>
              <Paper className={classes.paperContainer2}>
                <Typography variant="h4" className={classes.title}>
                  Mon Profile
                </Typography>
                <Divider className={classes.divider} />
                <Grid container className={classes.formContainer} spacing={0}>
                  <Grid item xs={12} className={classes.gridContainer}>
                    <Grid container>
                      <Grid item xs={4} className={classes.fieldContainer}>
                        <Typography variant="h6">Nom</Typography>
                      </Grid>
                      <Grid item xs={8} className={classes.inputContainer}>
                        <TextField
                          name="lastname"
                          value={tempProfileData.lastname}
                          onChange={handleChange}
                          fullWidth
                          variant="outlined"
                          error={!!validationErrors.lastname}
                          helperText={validationErrors.lastname}
                          disabled={showSuccessAlert}
                          size="small"
                          inputProps={{
                            style: {
                              color: theme.palette.primary.main,
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={classes.gridContainer}>
                    <Grid container>
                      <Grid xs={4} className={classes.fieldContainer}>
                        <Typography variant="h6">Prenom</Typography>
                      </Grid>
                      <Grid xs={8} className={classes.inputContainer}>
                        <TextField
                          name="firstname"
                          value={tempProfileData.firstname}
                          onChange={handleChange}
                          fullWidth
                          variant="outlined"
                          error={!!validationErrors.firstname}
                          helperText={validationErrors.firstname}
                          disabled={showSuccessAlert}
                          className={classes.textField}
                          size="small"
                          inputProps={{
                            style: {
                              color: theme.palette.primary.main,
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={classes.gridContainer}>
                    <Grid container>
                      <Grid item xs={4} className={classes.fieldContainer}>
                        <Typography variant="h6">Phone</Typography>
                      </Grid>
                      <Grid item xs={8} className={classes.inputContainer}>
                        <TextField
                          name="numTelephone"
                          value={tempProfileData.numTelephone}
                          onChange={handleChange}
                          fullWidth
                          variant="outlined"
                          error={!!validationErrors.numTelephone}
                          helperText={validationErrors.numTelephone}
                          disabled={showSuccessAlert}
                          size="small"
                          inputProps={{
                            style: {
                              color: theme.palette.primary.main,
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider
                      className={classes.divider}
                      sx={{ marginBottom: "0.4em" }}
                    />
                    <Grid container className={classes.buttonContainer}>
                      {showSuccessAlert ? (
                        <CustomButton
                          onClick={handleEdit}
                          variant="outlined"
                          className={classes.button}
                        >
                          <Edit className={classes.icon} />
                          <Typography>Modifier</Typography>
                        </CustomButton>
                      ) : (
                        <>
                          <CustomButton
                            onClick={handleClose}
                            variant="outlined"
                            className={classes.button}
                          >
                            Annuler
                          </CustomButton>
                          <CustomButton
                            onClick={handleSave}
                            variant="contained"
                            className={classes.button}
                          >
                            Enregistrer
                          </CustomButton>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Card>
      </DialogContent>
    </Dialog>
  );
}
