type TThemeConfig = {
  mode: 'light' | 'dark';
  routingLoader: boolean;
  disableRipple: boolean;
  navigationSize: number;
  menuTextTruncate: boolean;
  contentWidth: 'full' | 'boxed';
  responsiveFontSizes: boolean;
  fontFamily: string;
  webHeaderHeight: number;
  mobileHeaderHeight: number;
  sideBarWidth: number;
};

export const themeConfig: TThemeConfig = {
  mode: 'light',
  //fontFamily: 'Public Sans, sans-serif',
  fontFamily: 'Poppins',
  webHeaderHeight: 80 /* num in px */,
  mobileHeaderHeight: 63 /* px */,
  sideBarWidth: 250 /* px */,
  contentWidth: 'boxed',
  routingLoader: true,
  // ** Navigation (Menu) Configs
  menuTextTruncate: true /* true | false */,
  navigationSize: 260 /* Number in PX(Pixels) /*! Note: This is for Vertical navigation menu only */,
  // ** Other Configs
  responsiveFontSizes: true /* true | false */,
  disableRipple: false /* true | false */
};



type TAuthConfig = {
  domain: string;
  clientId: string;
  redirectUri: string;
  audience: string;
  scope: string;
  cacheLocation: 'localstorage' | 'memory';
  connection: string;
  roleClaim: string;
};

type TGlobalConfig = {
  api: any;
  auth: TAuthConfig;
  upload: {
    allowedFileExt: string[];
    allowedImageExt: string[];
    maxFileSize: number;
  };
  url: any;
  page: {
    size: number;
  };
  
};

export const GlobalConf: TGlobalConfig = {
  api: {
    base: {
      auth: process.env.REACT_APP_API_AUTH_URL ?? '',
      chantier: process.env.REACT_APP_API_CHANTIER_URL ?? '',
    },
    users: {
      all: (entityId: number): string => (entityId ? `/chantiers/${entityId}/users` : `/users`),
      one: (entityId: number, userId: string) =>
        entityId ? `/chantiers/${entityId}/users/${userId}` : `/users/${userId}`
    },
    chantiers: {
      'sign-up': '/chantiers/signup',
      all: (): string => `/chantiers/`,
      one: (entityId: number) => (entityId ? `/chantiers/${entityId}` : '/chantiers/'),
      import: '/chantiers/import'
    },
    reservations: {
      root: '/reservations',
      get: `/reservations/planning`,
      one: (reservationId: string): string => `/reservations/${reservationId}`,
      delete: (reservationId: string): string => `/reservations/${reservationId}`
    },
  },
  url: {
    local: process.env.REACT_APP_URL,
    wa: process.env.REACT_APP_WA_LINK ?? ''
  },
  auth: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN ?? '',
    clientId: process.env.REACT_APP_AUTH0_CLIENTID ?? '',
    audience: process.env.REACT_APP_AUTH0_AUDIENCE ?? '',
    scope: process.env.REACT_APP_AUTH0_SCOPE ?? '',
    connection: process.env.REACT_APP_AUTH0_CONNECTION ?? '',
    roleClaim: process.env.REACT_APP_AUTH0_ROLE_CLAIM ?? '',
    cacheLocation: 'localstorage',
    redirectUri: window.location.origin
  },
  upload: {
    allowedImageExt: ['.png', '.jpg', '.jpeg'],
    allowedFileExt: ['pdf', 'jpeg', 'png', 'doc', 'docx', 'csv', 'xlsx', 'xls'],
    maxFileSize: 10000000
  },
  page: {
    size: Number(process.env.REACT_APP_PER_PAGE ?? '')
  }
};
