import { AxiosResponse } from 'axios';
import {
    API_AUTH_URL,
    ACCESS_TOKEN,
    AUTH_AUTHENTICATE,
    AUTH_REGISTER,
    AUTH_USER_CONNECTED,
    AUTH_FORGOT_PASSWORD,
    AUTH_RESET_PASSWORD,
    AUTH_CONFIRME_EMAIL_CONNEXION,
    EDIT_USER_PROFILE,
    CHANGE_PASSWORD,
    REFRESH_TOKEN,
    USER_DETAILS,
    AUTH_REFRESH_TOKEN
} from '../utils/ApiUrlConstants';
import axiosInstance from './axios';

interface AuthResponse {
    access_token: string;
    refresh_token: string;
}

export class AuthApi {
    private basePath: string|undefined;

    constructor() {
        this.basePath = API_AUTH_URL;
        
        console.log('********************************' + this.basePath);
    }

    private setAuthorizationHeader() {
        if (localStorage.getItem(ACCESS_TOKEN)) {
            axiosInstance.defaults.headers.common['Authorization'] =
                'Bearer ' + localStorage.getItem(ACCESS_TOKEN);
            axiosInstance.defaults.headers.common['Content-Type'] =
                'application/json';
        }
    }

    private async request<T>(method: string, url: string, data?: any): Promise<T> {
        console.log(
            'method  ' + method + ' >> url ' + url + ' >> data ' + data
        );
        try {
            const response = await axiosInstance.request<T>({
                method,
                url: `${this.basePath}${url}`,
                data,
            });
            return response.data;
        } catch (error:any) {
            if (error.response && error.response.data) {
                throw error.response.data;
            } else {
                throw new Error('Une erreur est survenue lors de la demande.');
            }
        }
    }

    public async login(data: any): Promise<any> {
        axiosInstance.defaults.headers.common['Content-Type'] =
                'application/json';
        return this.request('post', AUTH_AUTHENTICATE, data);
    }

    public async refreshToken(): Promise<string> {
        try {
          axiosInstance.defaults.headers.common['Authorization'] =
                'Bearer ' + localStorage.getItem(REFRESH_TOKEN);            
          const response: AuthResponse = await this.request('post', AUTH_REFRESH_TOKEN);
          const { access_token, refresh_token } = response;

          // supprimer les anciens et stocker les nouveaux jetons
          localStorage.removeItem(ACCESS_TOKEN);
          localStorage.removeItem(REFRESH_TOKEN);
          localStorage.setItem(ACCESS_TOKEN, access_token);
          localStorage.setItem(REFRESH_TOKEN, refresh_token)
          return access_token;
        } catch (error) {
          console.error('erreur du refresh token:', error);
          throw error;
        }
      };

    public async userDetails(): Promise<any> {
        this.setAuthorizationHeader();
        return this.request('get', AUTH_USER_CONNECTED);
    }

    public async signup(data: any): Promise<any> {
        if (localStorage.getItem(ACCESS_TOKEN)) {
            axiosInstance.defaults.headers.common['Authorization'] =
                'Bearer ' + localStorage.getItem(ACCESS_TOKEN);
            axiosInstance.defaults.headers.common['Content-Type'] =
                'multipart/form-data';
        }
        return this.request('post', AUTH_REGISTER, data);
    }

    public async facebookLogin(data: any): Promise<any> {
        return this.request('post', '/facebookLogin', data);
    }

    public async refresh(): Promise<any> {
        this.setAuthorizationHeader();
        return this.request('get', `/refresh`);
    }

    public async emailverification(activationToken: string): Promise<any> {
        return this.request(
            'get',
            AUTH_CONFIRME_EMAIL_CONNEXION + `?token=${activationToken}`
        );
    }

    public async forgotPassword(data: any): Promise<any> {
        return this.request('post', AUTH_FORGOT_PASSWORD, data);
    }

    public async resetpassword(data: any): Promise<any> {
        return this.request(
            'post',
            `${AUTH_RESET_PASSWORD}?resetPasswordToken=${data.resetPasswordToken}`,
            data
        );
    }

    public async logout(): Promise<any> {
        this.setAuthorizationHeader();
        const response = this.request('get', '/api/v1/auth/logout');

        localStorage.setItem(ACCESS_TOKEN, "");
        localStorage.setItem(REFRESH_TOKEN, "");
        localStorage.setItem(USER_DETAILS, "");
        return response;
    }

    public async editProfile(data: any): Promise<any> {
        if (localStorage.getItem(ACCESS_TOKEN)) {
            axiosInstance.defaults.headers.common['Authorization'] =
                'Bearer ' + localStorage.getItem(ACCESS_TOKEN);
            axiosInstance.defaults.headers.common['Content-Type'] =
                'multipart/form-data';
        }
        console.log('data :'+ data);
        return this.request('put', EDIT_USER_PROFILE, data);
    }

    public async changePassword(data: any): Promise<any> {
        this.setAuthorizationHeader();
        
        return this.request('patch', CHANGE_PASSWORD, data);
    }
}
