import React from "react";
import { Link } from "react-router-dom";
import "./MenuBar.css"; // Si vous souhaitez ajouter des styles
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const MenuBar: React.FC = () => {
  const theme = useTheme();

  return (
    <nav>
      <ul>
        <li>
          <Link to="/personnels">
            <Typography
              variant="h5"
              gutterBottom
              align="center"
              style={{ marginBottom: "5px", color: theme.palette.primary.main }}
            >
              Gestion Personnel
            </Typography>
          </Link>
        </li>
        <li>
          <Link to="/fournisseurs">
            <Typography
              variant="h5"
              gutterBottom
              align="center"
              style={{ marginBottom: "5px", color: theme.palette.primary.main }}
            >
              Gestion Fournisseurs
            </Typography>
          </Link>
        </li>
        <li>
          <Link to="/stocks">
            <Typography
              variant="h5"
              gutterBottom
              align="center"
              style={{ marginBottom: "5px", color: theme.palette.primary.main }}
            >
              Gestion Stocks
            </Typography>
          </Link>
        </li>
        <li>
          <Link to="/cra-global">
            <Typography
              variant="h5"
              gutterBottom
              align="center"
              style={{ marginBottom: "5px", color: theme.palette.primary.main }}
            >
              Rapport Activité
            </Typography>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default MenuBar;
