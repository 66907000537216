import axios, {
  Method,
  ResponseType,
  AxiosResponse,
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  InternalAxiosRequestConfig
} from 'axios';
import { GlobalConf } from '../../conf/globalConfig';

const addHttpHeaders = (
  config: AxiosRequestConfig,
  token: string
): AxiosRequestConfig => {
  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${token}`
    }
  };
};

type Request = {
  method: Method;
  url: string;
  data?: any;
  params?: any;
  responseType?: ResponseType;
};

export class HttpClient {
  instance: AxiosInstance;

  constructor(baseURL: string = GlobalConf.api.base.chantier) {
    this.instance = axios.create({ baseURL });
  }

  interceptors(tokenGenerator: Function) {
    // request interceptors
    this.instance.interceptors.request.use(
      async (config: AxiosRequestConfig) => {
        const token = await tokenGenerator();
        return addHttpHeaders(config, token) as InternalAxiosRequestConfig
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // response interceptors
  }

  async onSuccess<T>(response: AxiosResponse) {
    console.debug('Request Successful!', response);
    return response.data as T;
  }

  async onError(error: AxiosError) {
    console.error('Request Failed:', error.config);
    if (error.response) {
      console.error('Status:', error.response.status);
      console.error('Data:', error.response.data);
      console.error('Headers:', error.response.headers);
    } else {
      console.error('Error Message:', error.message);
    }
    return Promise.reject(error.response?.data || error.message);
  }

  async request<T>({ method, url, data, params, responseType }: Request) {
    return this.instance({
      method,
      url,
      responseType,
      data,
      params
    })
      .then(this.onSuccess<T>)
      .catch(this.onError);
  }

  async get<T>(url: string, params?: any, responseType?: ResponseType) {
    return this.request<T>({ method: 'GET', url, params, responseType });
  }

  async post<T>(
    url: string,
    data: any,
    params?: any,
    responseType?: ResponseType
  ) {
    return this.request<T>({
      method: 'POST',
      url,
      data,
      params,
      responseType
    });
  }

  async put<T>(
    url: string,
    data: any,
    params?: any,
    responseType?: ResponseType
  ) {
    return this.request<T>({
      method: 'PUT',
      url,
      data,
      params,
      responseType
    });
  }

  async delete<T>(url: string, params?: any, responseType?: ResponseType) {
    return this.request<T>({
      method: 'DELETE',
      url,
      params,
      responseType
    });
  }
}

const httpClient = new HttpClient();
const cmHttpClient = new HttpClient(GlobalConf.api.base.cm);

export { httpClient, cmHttpClient };
export default httpClient;
