import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { Link, LinkProps } from 'react-router-dom';
import { SxProps, Theme } from '@mui/system';
import { useTheme } from '@mui/material/styles';

interface CustomButtonProps extends ButtonProps {
  icon?: React.ElementType;
  includeSpacing?: boolean;
  sx?: SxProps<Theme>;
  to?: string;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  icon: Icon,
  children,
  includeSpacing = false,
  sx = {},
  to,
  ...props
}) => {
  const theme = useTheme();

  const buttonStyle: SxProps<Theme> = {
    borderColor: theme.palette.secondary.dark,
    backgroundColor: theme.palette.secondary.main,
    
    fontSize: "14px",
    fontWeight: "bold",
    transition: 'background-color 0.4s, color 0.4s', // Transition pour l'effet de survol
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
    ...(includeSpacing ? { marginBottom: theme.spacing(2) } : {}),
    ...sx,
  };

  const iconStyle = {
    width: 24,
    height: 24,
    position: 'absolute',
    left: 10,
    top: 4,
  };

  if (to) {
    return (
      <Button
        component={Link}
        to={to}
        {...props}
        sx={buttonStyle}
      >
        {Icon && <Icon style={iconStyle} />}
        {children}
      </Button>
    );
  }

  return (
    <Button variant="contained" {...props} sx={buttonStyle}>
      {Icon && <Icon style={iconStyle} />}
      {children}
    </Button>
  );
};

export default CustomButton;