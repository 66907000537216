import React, { createContext, useState } from 'react';
import * as Yup from 'yup';
import { ChampFormulaireInterface } from './ChampFormulaireInterface';

const ValidationContext = createContext({
    validation: async (champState: ChampFormulaireInterface<any>) => {
        let errorMessage = '';
        const validationSchema = createGenericSchema(champState);
        try {
            const field: { [key: string]: string } = {};
            field[champState.name] = champState.value + '';
            await validationSchema.validate(field, {
                abortEarly: false,
            });

            return {
                isValid: true,
                errorMessage: errorMessage,
            };
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const errors: string[] = [];
                error.inner.forEach((err) => {
                    if (err.path) {
                        errors.push(`${err.message}`);
                    }
                });
                errorMessage = errors.join('\n');
            }
            return {
                isValid: false,
                errorMessage: errorMessage,
            };
        }
    },
});

function createGenericSchema(champState: ChampFormulaireInterface<any>) {
    const schema: { [key: string]: any } = {};
    let fieldSchema;

    if (champState.type === 'number') {
        fieldSchema = Yup.number();
    } else {
        if (champState.matches !== undefined) {
            fieldSchema = Yup.string().matches(
                champState.matches.regex,
                champState.matches.message
            );
        } else {
            fieldSchema = Yup.string();
        }
    }

    if (champState.required) {
        fieldSchema = fieldSchema.required(`${champState.libelle} est requis`);
    }

    if (champState.min !== undefined) {
        fieldSchema = fieldSchema.min(
            champState.min,
            `${champState.libelle} doit contenir au minimum ${champState.min} caractères`
        );
    }

    if (champState.max !== undefined) {
        fieldSchema = fieldSchema.max(
            champState.max,
            `${champState.libelle} doit contenir au maximum ${champState.max} caractères`
        );
    }

    schema[champState.name] = fieldSchema;

    return Yup.object().shape(schema);
}

export default ValidationContext;
