import { all, takeLatest, put } from 'redux-saga/effects';
import {
  setFullScreenAction,
  setHideHeaderAction,
  setSidebarAction
} from './layout.actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { layoutActions } from './layout.slice';

export function* watchFullScreen(value: PayloadAction<boolean>) {
  try {
    yield put(layoutActions.setFullScreen(value.payload));
  } catch (error) {
    console.error('error: ', error);
  }
}

export function* watchHideHeader(value: PayloadAction<boolean>) {
  try {
    yield put(layoutActions.setHideHeader(value.payload));
  } catch (error) {
    console.error('error: ', error);
  }
}

export function* watchOpenSidebar(value: PayloadAction<boolean>) {
  try {
    yield put(layoutActions.setOpenSidebar(value.payload));
  } catch (error) {
    console.error('error: ', error);
  }
}

export function* layoutSagaWatcher(): Generator {
  yield all([takeLatest(setHideHeaderAction, watchHideHeader)]);
  yield all([takeLatest(setSidebarAction, watchOpenSidebar)]);
  yield all([takeLatest(setFullScreenAction, watchFullScreen)]);
}
