import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware, { END } from 'redux-saga';
import { rootReducer } from '../../state/root.reducers';
import rootSaga from '../../state/root.saga';
import counterReducer from './counterSlice';

// Middleware: Redux uses middleware for customizing the dispatch functions, can also be used for crash reporting, logging, asynchronous performance of a task, etc
const reduxSagaMonitorOptions = {};
const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

const configureAppStore = (preloadedState = {}) => {
  const middleware = [sagaMiddleware];
  const store = configureStore({
    reducer: rootReducer,
    middleware: (gDM) =>
      gDM({ serializableCheck: false }).concat([...middleware]),
    preloadedState,
    devTools: process.env.NODE_ENV !== 'production'
  });
  sagaMiddleware.run(rootSaga);
  return store;
};

const store = configureAppStore();
export const runSaga = sagaMiddleware.run;
export const close = () => store.dispatch(END);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
