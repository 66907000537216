import { apiActions, apiType, createAction } from "../action.creator";

export const USERS = {
  ...apiType('USERS'),
  RESET_USER_ACTION_STATE: 'RESET_USER_ACTION_STATE',
};

export const usersActions = {
  ...apiActions(apiType('USERS')),
  resetActionState: () =>
    createAction(USERS.RESET_USER_ACTION_STATE),
};
