import React, { useRef, useState, MouseEvent, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { alpha } from "@mui/material/styles";
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  IconButton,
} from "@mui/material";
// components
import MenuPopover from "../../common/MenuPopover";
import EditProfile from "../../pages/edit-profile/EditProfile";
import ChangePasswordForm from "../../pages/change-password/ChangePasswordFom";

// mocks_
import account from "../../_mock/account";
import { authApi } from "../../apis/authApi";
import { TConnectedUser } from "../../model/User";

// ----------------------------------------------------------------------

interface MenuOption {
  label: string;
  icon: string;
  linkTo: string;
  onClick?: () => void;
}

// ----------------------------------------------------------------------

const AccountPopover: React.FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const [userConnected, setUserConnected] = useState<TConnectedUser | null>(
    null
  );
  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };
  const [isEditProfileOpen, setIsEditProfileOpen] = useState<boolean>(false);
  const [isChangePasswordOpen, setIsChangePasswordOpen] =
    useState<boolean>(false);

  const handleCloseMenuItem = () => {
    setOpen(null);
  };
  const handleLogout = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    await authApi.logout();
    setOpen(null);
    window.location.href = "/";
  };

  const fetchUserConnected = async () => {
    const response = await authApi.userDetails();
    const user: TConnectedUser = {
      username: response.email,
      firstname: response.prenom,
      lastname: response.nom,
      telephone: response.telephone,
      photoProfile: response.photoProfile,
    };
    setUserConnected(user);
  };

  useEffect(() => {
    fetchUserConnected();
  }, []);

  const MENU_OPTIONS: MenuOption[] = [
    {
      label: "Profile",
      icon: "eva:person-fill",
      linkTo: "",
      onClick: () => {
        setIsEditProfileOpen(true);
      },
    },
    {
      label: "Mot de passe",
      icon: "eva:home-fill",
      linkTo: "",
      onClick: () => {
        setIsChangePasswordOpen(true);
      },
    },
    {
      label: "Upgrade Compte",
      icon: "eva:settings-2-fill",
      linkTo: "/abonnement",
    },
  ];

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar
          src={`data:image/jpeg;base64,${userConnected?.photoProfile}`}
          alt="photoURL"
        />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenuItem}
        sx={{
          padding: 0,
          marginTop: 1.5,
          marginLeft: 0.75,
        }}
      >
        {userConnected !== null && (
          <Box sx={{ my: 1.5, px: 2.5 }}>
            <Typography variant="subtitle2" noWrap>
              {userConnected.firstname + " " + userConnected.lastname}
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
              {userConnected.email}
            </Typography>
          </Box>
        )}
        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={() => {
                if (option.onClick !== undefined) option.onClick();
                handleCloseMenuItem();
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
      {userConnected !== null && (
        <EditProfile
          isOpen={isEditProfileOpen}
          onClose={() => setIsEditProfileOpen(false)}
          connectedUser={userConnected}
          fetchUserConnected={fetchUserConnected}
        />
      )}
      <ChangePasswordForm
        isOpen={isChangePasswordOpen}
        handleClose={() => setIsChangePasswordOpen(false)}
      />
    </>
  );
};

export default AccountPopover;
